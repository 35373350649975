.modal-style {
  overflow: hidden;
  padding-right: 17px;
}
label.error {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #ff0c3e;
  margin-bottom: 0;
}
.custom-input label.error {
  width: 100%;
}
.btn-loading2 img {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.cm-pagination li {
  padding: 0 5px;
}
.cm-pagination li a.active,
.cm-pagination li a:not(.btn):hover {
  background: #ebc7b0;
}
.cm-pagination li > a {
  background: #f5f5f5;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  color: #000;
}
.five-column-slider .swiper-wrapper {
  padding: 0 !important;
}
.dashboard-list li.dl-item {
  width: 33.33% !important;
}

@media (max-width: 579.98px) {
  .dashboard-list li.dl-item {
    width: 100% !important;
  }
}
@media (min-width: 579.98px) and (max-width: 767.98px) {
  .dashboard-list li.dl-item {
    width: 50% !important;
  }
}
.cm-tooltip:hover::after {
  width: 175px !important;
}
.btn-primary:disabled {
  background-color: transparent;
}
.cm-next-btn {
  margin-left: auto;
}
.cm-prev-btn {
  margin-right: auto;
}
.MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}
.MuiInputAdornment-root {
  z-index: 1;
}
.MuiOutlinedInput-input {
  background: #f5f5f5 !important;
}
.MuiFormControl-root {
  background: #f5f5f5 !important;
}
.react-datepicker__time-container {
  width: 103px !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 103px !important;
}
.btn-disabled:disabled {
  background-color: #000 !important;
}
.dashboard-bg {
  background-image: url("../images/dashboard-bg.svg");
}
.donate-shape {
  background-image: url("../images/donate-shape.svg");
}
.inner-hero-bg {
  background-image: url("../images/forum-banner-1.jpg");
}
.comming-soon {
  background-image: url("../images/shape-4.png");
}

.inner-hero-text {
  padding: 30px 10% 45px 5.5%;
}

.contact-form-box .form-group label.error {
  font-size: 12px;
}

.btn-comment {
  background-color: #666;
  border: solid 1px #666;
}
.btn-comment:hover,
.btn-comment:active {
  color: #666;
  background-color: transparent;
  border: solid 1px #666;
}
