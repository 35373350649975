.hero-circle {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding-bottom: 100%;
}
.hero-circle img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.hero-cicle-box {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 50%;
  height: 50%;
  transform-origin: 100% 100%;
  transition: all 1s ease-in;
}
/*.hero-cicle-box.one{transform: rotate(0deg); } 
.hero-cicle-box.two{ transform: rotate(0deg);}
.hero-cicle-box.three{ transform: rotate(0deg);}
.hero-cicle-box.four{ transform: rotate(0deg);}*/
.hero-circle-inside {
  width: 200%;
  height: 200%;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 1s ease-in;
}
.hero-circle-inside img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.hero-cicle-box.one .hero-circle-inside img {
  transform: rotate(-90deg);
}
.hero-cicle-box.two .hero-circle-inside img {
  transform: rotate(-180deg);
}
.hero-cicle-box.three .hero-circle-inside img {
  transform: rotate(-270deg);
}
.hero-cicle-box.four .hero-circle-inside img {
  transform: rotate(-360deg);
}
.hero-cicle-box.one {
  transform: rotate(90deg);
}
.hero-cicle-box.two {
  transform: rotate(180deg);
}
.hero-cicle-box.three {
  transform: rotate(270deg);
}
.hero-cicle-box.four {
  transform: rotate(360deg);
}
.hero-circle-holder {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: transparent;
  box-sizing: border-box;
  transform: rotate(-90deg);
  transform-origin: 100% 100%;
  transition: all 1s ease-in;
}
.animated .hero-circle-holder {
  transform: rotate(0deg);
}
.animated .hero-cicle-box.one .hero-circle-holder {
  transition-delay: 1s;
}
.animated .hero-cicle-box.two .hero-circle-holder {
  transition-delay: 2s;
}
.animated .hero-cicle-box.three .hero-circle-holder {
  transition-delay: 3s;
}
.animated .hero-cicle-box.four .hero-circle-holder {
  transition-delay: 4s;
}
